/*choose collection start*/

.choose_collection_wrap {
    background: url(../assets/images/shadow_collection.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 52px 0 106px;
}
.choose_collection {
    text-align: center;
    padding: 0px 0 59px;
}
.choose_collection p,.stay_intouch p {
    font-size: 18px;
    padding: 16px 0 0;
    margin: 0;
}
.card_box{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-right: 15px;
    padding-left: 15px;
}
.inner_card {
    background: linear-gradient(155.14deg, rgba(255, 255, 255, 0) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
    filter: drop-shadow(0px 4px 49px rgba(0, 7, 72, 0.12));
    backdrop-filter: blur(25px);
    border-radius: 20px;
    padding: 24px;
    border: 2px solid #4c7195;
}
.inner_card img {
    width: 100%;
    padding: 0 0 8px;
    height: 349px;
    object-fit: contain;
    cursor: pointer;
}
.inner_card .overflow_text {
    margin: 0;
    height: 113px;
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
}
.inner_card p {
    font-size: 15px;
    line-height: 26px;
}
.inner_card a {
    background: #8197AC;
    box-shadow: 0px 4px 49px rgb(0 7 72 / 12%);
    backdrop-filter: blur(25px);
    border-radius: 4px;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 13px 32px;
}
.inner_card p > span{
    float: right;
}
.inner_card:hover a{
    background: #439ff1;
}
.inner_card:hover{
    background: #0c3464;
}
.swarovski_bear img {
    width: 100%;
    display: block;
}
.choose_collection .mobile_show{
    display: none;
}
.card_box video {
    width: 100%;
    height: 347px;
    border-radius: 14px;
}

/*choose collection end*/

@media(max-width: 1250px){
    .container{
        width: 100%;
    }
}

@media(max-width: 1024px){
    .container{
        width: 100%;
    }
    .inner_card img {
        height: 213px;
    }
    .inner_card {
        padding: 15px;
    }
    .choose_collection p{
        font-size: 16px;
        padding: 12px 0 0;
    }
    .inner_card .overflow_text {
        height: 128px;
        max-width: 512px;
        -webkit-line-clamp: 5;
        font-weight: 400;
        font-size: 16px;
    }
    .choose_collection img{
        width: 600px;
    }
    .card_box {
        padding-right: 4px;
        padding-left: 4px;
    }
    .inner_card p {
        font-size: 12px;
        line-height: 20px;
    }
    .inner_card a {
        padding: 13px 8px;
        font-size: 12px;
    }
    .choose_collection_wrap {
        padding: 52px 16px 106px;
    }
    
}

@media(max-width: 767px){
    .container{
        width: auto;
    }
    .choose_collection_wrap {
        padding: 24px 15px;
    }
    .choose_collection p {
        font-size: 14px;
    }
    .choose_collection {
        padding: 0px 0 21px;
    }
    .card_box {
        flex: 100%;
        max-width: 100%;
    }
    .inner_card .overflow_text {
        height: auto;
        max-width: inherit;
        -webkit-line-clamp: inherit;
        font-weight: 400;
        font-size: 14px;
        visibility: visible;
        overflow: visible;
        text-overflow: inherit;
        line-height: 1.6;
        padding: 0 0 11px;
    }
    .inner_card {
        margin: 0 0 21px;
    }
    .choose_collection .hide_mobile{
        display: none;
    }
    .choose_collection .mobile_show{
        display: block;
    }
    .choose_collection img {
        width: 284px;
    }
    .inner_card img {
        height: auto;
    }
    .card_box video {
        height: auto;
        margin: 0 0 10px;
    }
}

