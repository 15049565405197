/*header start*/
.logobox ,.user_login{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.menubar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.header_warp {
    background: #011329;
    padding: 16px 0 12px;
}
.logobox,.user_login {
    padding: 0;
}
.logobox img {
    width: 100%;
    padding: 2px 0 0;
}
.menubar {
    padding: 11px 0;
    text-align: center;
}
.menubar ul li {
    display: inline-block;
}
.menubar ul li a {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    display: inline-block;
    padding: 2px 26px;
}
.menubar ul li a:hover,.widget_nav ul > li > a:hover{
    color: #439FF1;
}
.user_login {
    text-align: right;
}
.user_login a {
    background: #439FF1;
    box-shadow: 0px 4px 49px rgb(0 7 72 / 12%);
    backdrop-filter: blur(25px);
    border-radius: 4px;
    padding: 12px 27px;
    display: inline-block;
    font-size: 17px;
}
.menu_btn,.closebtn,.menubar .mobile_list,.logobox .mobile_logo,.user_login .mobile_login{
    display: none;
}
.play_btn_bottom #play_btnvideo,.playbtn #play_btnvideo2{
    display: none;
}

/*header end*/


@media(max-width: 1250px){
    .container{
        width: 100%;
    }
    .header_warp {
        padding: 16px 18px 12px;
    }

}

@media(max-width: 1024px){
    .container{
        width: 100%;
    }
    .menubar {
        padding: 71px 0 30px;
    }
    .menubar {
        flex: 100%;
        max-width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #011329;
        height: 100%;
        z-index: 99;
        overflow-y: auto;
        display: none;
    }
    .closebtn {
        display: block;
        position: absolute;
        right: 46px;
        top: 24px;
    }
    .closebtn svg {
        cursor: pointer;
        width: 26px;
        height: 15px;
    }
    .menubar ul li {
        display: block;
        padding: 0 0 19px;
    }
    #header_menu{
        display: inline-block;
        width: 100%;
    }
    .menu_btn {
        display: block;
        position: absolute;
        left: 25px;
        top: 32px;
        z-index: 9;
    }
    .logobox{
        text-align: center;
        flex: 100%;
        width: 100%;
        max-width: 100%;
        display: inline-block;
        margin: 0 auto;
    }
    .logobox img {
        width: 295px;
        padding: 0;
    }
    .user_login {
        text-align: right;
        width: 18%;
        max-width: 18%;
        position: absolute;
        top: 17px;
        right: 15px;
    }
    .user_login a {
        padding: 9px 18px;
        font-size: 16px;
    }
    .header_warp {
        padding: 5px 18px 12px;
    }
}

@media(max-width: 767px){
    .container{
        width: auto;
    }
    .logobox .hidelogo,.user_login .hide_btn{display: none;}
    .menubar .mobile_list{display: block;}
    .logobox .mobile_logo {
        display: block;
        width: 142px;
        position: absolute;
        left: 71px;
        top: 12px;
    }
    .user_login .mobile_login{
        display: block;
    }
    .user_login a:hover{background: transparent;}
    .user_login a {
        background: transparent;
        padding: 14px 0 0;
        margin: 0;
    }
    .header_warp {
        padding: 5px 18px 45px;
    }
}