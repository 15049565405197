/*stay start*/
.stay_touch {
    background: #011329;
    padding: 0 0 86px;
}
.stay_intouch {
    text-align: center;
}
.formbox{
    position: relative;
}
.input_box_stay {
    text-align: center;
    padding: 28px 0 0;
}
.input_box_stay input {
    background: #fff;
    width: 400px;
    border: none;
    height: 47px;
    vertical-align: middle;
    margin: 0 7px;
    border-radius: 5px;
    padding: 0 19px;
    font-size: 16px;
}
.input_box_stay button {
    background: #439FF1;
    box-shadow: 0px 4px 49px rgb(0 7 72 / 12%);
    backdrop-filter: blur(25px);
    padding: 17px 32px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    vertical-align: middle;
    border: none;
    outline: 0;
}
/*stay end*/

@media(max-width: 1250px){
    .container{
        width: 100%;
    }
}

@media(max-width: 1024px){
    .container{
        width: 100%;
    }
    .stay_intouch img {
        width: 305px;
    }
    .stay_touch {
        padding: 0 65px 86px;
    }
}

@media(max-width: 767px){
    .container{
        width: auto;
    }
    .stay_touch {
        padding: 0 15px 24px;
    }
    .stay_intouch img {
        width: 171px;
    }
    .stay_intouch p {
        font-size: 15px;
        padding: 6px 0 0;
        margin: 0;
    }
    .input_box_stay input {
        margin: 0 0 10px;
    }
    .input_box_stay button,.input_box_stay input {width: 100%;}
}