/*meat bear start*/

.meet_bearwrap{
    position: relative;
}
.limited_box{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.bid_now{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.playbtn{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.video_box {
    position: relative;
}
.video_box > img {
    display: block;
    width: 100%;
}
.video_info {
    position: absolute;
    bottom: 42px;
    width: 100%;
    padding: 0 46px;
}
.limited_box h3 {
    font-size: 40px;
    line-height: 1.2;
}
.limited_box p {
    margin: 12px 0 0;
    font-size: 22px;
}
.bid_now {
    padding: 56px 0 0;
}
.bid_now a {
    padding: 12px 32px;
    background: #439FF1;
    box-shadow: 0px 4px 49px rgb(0 7 72 / 12%);
    backdrop-filter: blur(25px);
    border-radius: 4px;
    font-size: 18px;
}
.playbtn {
    text-align: right;
}
.playbtn img{
     cursor: pointer;

}
.playbtn a img {
    width: auto;
    display: inherit;
}
.swarovski_bear .show_img{
    display: none;
}
.video_box > video {
    width: 100%;
    display: block;
}
.meet_bplay,.playbtn #meetbearplay,.meet_bplay #meetbearplay2{display: none;}
/*meat bear end*/


@media(max-width: 1250px){
    .container{
        width: 100%;
    }
    .limited_box h3 {
        font-size: 29px;
    }
    .bid_now {
        padding: 39px 0 0;
    }
    
}

@media(max-width: 1024px){
    .container{
        width: 100%;
    }
    .limited_box h3 {
        font-size: 22px;
    }
    .bid_now {
        flex: 0 0 22.666667%;
        max-width: 22.666667%;
        text-align: right;
    }
    .playbtn a img {
        width: 102px;
    }
    .playbtn {
        flex: 0 0 8.666667%;
        max-width: 8.666667%;
    }
}

@media(max-width: 767px){
    .container{
        width: auto;
    }
    .video_info {
        position: static;
        padding: 19px 15px;
        background: #011329;
    }
    .limited_box ,.bid_now,.playbtn{
        flex: 100%;
        max-width: 100%;
    }
    .limited_box h3 {
        font-size: 20px;
        line-height: 1.4;
    }
    .limited_box p {
        margin: 6px 0 0;
        font-size: 13px;
    }
    .bid_now {
        text-align: center;
        padding: 13px 0 0;
    }
    .bid_now a{width: 100%;display: inline-block;font-size: 17px;padding: 9px 32px;}
    .playbtn {
        position: absolute;
        right: 0;
        bottom: 203px;
    }
    .playbtn img{display: none;}
    .swarovski_bear{ background: #03172f;}
    .swarovski_bear .hide_img{display: none;}
    .swarovski_bear .show_img{
        display:block;
    }
    .meet_bplay {
        display: block;
        bottom: 19px;
        right: 53px;
        cursor: pointer;
    }
    .meet_bplay img{display: block;}
    .meet_bplay #meetbearplay2{
        position: absolute;
        bottom: 0px;
    }
    .meet_bearwrap .playbtn a img{display: none;}
}


