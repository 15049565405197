/*footer start*/
.sweet_logo,.footer_widget{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
}
.footer_wrap {
    background: #202631;
    padding: 35px 0;
}
.widget_nav h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    padding: 0 0 8px;
}
.widget_nav ul > li > a {
    color: #fff;
    font-weight: 450;
    font-size: 16px;
    display: inline-block;
    padding: 0 0 3px;
}
.widget_nav > a {
    display: inline-block;
    width: 38%;
}
.widget_nav > a > img {
    margin: 6px 0 6px 0;
}
.widget_nav{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 15px;
}

/*footer end*/
@media(max-width: 1250px){
    .container{
        width: 100%;
    }
}

@media(max-width: 1024px){
    .container{
        width: 100%;
    }
    .footer_wrap {
        padding: 35px 27px;
    }
    .sweet_logo {
        flex: 0 0 34%;
        max-width: 34%;
    }
    .footer_widget{
        flex: 0 0 66%;
        max-width: 66%;
    }
    .widget_nav {
        padding: 0 2px;
    }
    .widget_nav ul > li > a {
        font-size: 15px;
    }
}

@media(max-width: 767px){
    .container{
        width: auto;
    }
    .footer_wrap {
        padding: 26px 5px 13px;
    }
    .sweet_logo ,.footer_widget,.widget_nav{
        flex: 100%;
        max-width: 100%;
    }
    .sweet_logo{
        padding-bottom: 10px;
    }
    .widget_nav {
        padding: 15px 0 0;
    }
    .widget_nav h4 {
        font-weight: 600;
        font-size: 18px;
        padding: 0 0 10px;
    }
    .widget_nav > a {
        width: auto;
        padding: 0 17px 0 0;
    }
}