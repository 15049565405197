/*mian section start*/

.main_wrap {
    background: url(../assets/images/shadow-img.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 86px 0;
}
.celebrate_info {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding: 0 0 0 90px;
}
.celebrate_info h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    padding: 0 0 19px;
}
.celebrate_info p {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 28px;
}
.celebrate_box {
    text-align: center;
    padding: 50px 0 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.celebrate_box img {
    cursor: pointer;
}

/*mian section end*/


@media(max-width: 1250px){
    .container{
        width: 100%;
    }
}

@media(max-width: 1024px){
    .container{
        width: 100%;
    }
    .celebrate_info p {
        font-size: 14px;
    }
    .celebrate_info {
        padding: 0 0 0 59px;
    }
    .celebrate_info h2 {
        font-size: 25px;
    }
    .main_wrap {
        padding: 59px 0;
    }
}

@media(max-width: 767px){
    .container{
        width: auto;
    }
    .celebrate_info {
        padding: 0 14px;
        width: 100%;
        max-width: 100%;
        flex: 100%;
    }
    .celebrate_info h2 {
        font-size: 20px;
        padding: 0 0 3px;
    }
    .main_wrap {
        padding: 25px 0;
    }
    .celebrate_box {
        padding: 0 ;
        flex: 100%;
        max-width: 100%;
    }
    .celebrate_box img {
        width: 163px;
    }
}
