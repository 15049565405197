/*follow start*/

.follow_wrap{
    background: #011329;
    padding: 48px 0 86px;
}
.socials_icons_box{
    text-align: center;
    position: relative;
    width: 784px;
    margin: 0 auto;
}
.socials_icons_box a {
    background: linear-gradient(155.14deg, rgba(255, 255, 255, 0) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
    filter: drop-shadow(0px 4px 49px rgba(0, 7, 72, 0.12));
    backdrop-filter: blur(25px);
    border-radius: 4px;
    margin: 21px 6px;
    display: inline-block;
    vertical-align: middle;
    width: 67px;
    height: 67px;
    border: 2px solid #1f2d43;
    padding: 16px 0 0;
}
.socials_icons_box a img {
    width: 31px;
    object-fit: contain;
    height: 31px;
}
.socials_icons_box a:hover{
    background: #7bbbf5;
}
.socials_icons_box:before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -90px;
    width: 147px;
    height: 184px;
    background: url(../assets/images/stars.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.socials_icons_box:after{
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: -97px;
    width: 190px;
    height: 215px;
    background: url(../assets/images/mobilestars.png);
    background-repeat: no-repeat;
    background-size: cover;
}

/*follow end*/
@media(max-width: 1250px){
    .container{
        width: 100%;
    }
}

@media(max-width: 1024px){
    .container{
        width: 100%;
    }
    .socials_icons_box {
        width: 100%;
    }
}

@media(max-width: 767px){
    .container{
        width: auto;
    }
    .follow_wrap .stay_intouch img {
        width: 234px;
    }
    .follow_wrap {
        padding: 35px 0 30px;
    }
    .socials_icons_box:before {
        left: -6px;
        top: -25px;
        width: 50px;
        height: 67px;
    }
    .socials_icons_box:after {
        right: 0;
        top: -46px;
        width: 68px;
        height: 91px;
    }
    .socials_icons_box a img {
        width: 24px;
        height: 24px;
    }
    .socials_icons_box a {
        width: 49px;
        height: 49px;
        padding: 11px 0 0;
    }
}