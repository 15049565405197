/*faq start*/

.faq_wrap{
    background: url(../assets/images/faq-shadow.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 52px 0 106px;
}
.faq_title {
    text-align: center;
    padding: 0 0 27px;
}
.tabs{
    padding: 0 70px;
}
.tab{width:100%;color:white;overflow:hidden;border-radius:7px;padding:0px 15px 0;}
.tab-label{display:flex;-webkit-box-pack:justify;justify-content:space-between;padding:15px 0;background:none;cursor:pointer;font-weight: 400;font-size: 22px;line-height: 30px;
color: #fff;border-bottom: 1px solid #FFFFFF;}
.tab-label::after {
    content: "\276F";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
    transform: rotate(90deg);
}
.tab-content{max-height:0;background: transparent;-webkit-transition:all 0.35s;transition:all 0.35s;}
.according_box input:checked + .tab-label{background:none;}
.according_box  input:checked + .tab-label::after{transform:rotate(90deg);}
.according_box  input:checked ~ .tab-content{max-height:initial;padding: 14px 0 10px;font-size: 18px;}
.tab input{display:none;}
.according_box .col{padding:0;}
.faq_title .faq_mobile_title{
    display: none;
}

/*faq end*/
@media(max-width: 1250px){
    .container{
        width: 100%;
    }
}

@media(max-width: 1024px){
    .container{
        width: 100%;
    }
    .faq_title img{
        width: 420px;
    }
    .tab-label {
        font-size: 17px;
    }
}

@media(max-width: 767px){
    .container{
        width: auto;
    }
    .faq_wrap {
        padding: 18px 0 30px;
    }
    .faq_title img{display: none;}
    .faq_title .faq_mobile_title{
        display: block;
    }
    .tabs {
        padding: 0 ;
    }
    .faq_title img {
        width: 297px;
        margin: 0 auto;
    }
    .faq_title {
        padding: 0 0 8px;
    }
    .tab-label {
        padding: 5px 3px 5px 0;
        line-height: 22px;
        font-size: 14px;
    }
}