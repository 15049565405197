/*hero section start*/

.banner_wrap {
    padding: 0 0 112px;
    overflow: hidden;
    position: relative;
}
.banner_wrap video {
    width: 100%;
    /*height: 624px;*/
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}
.banner_info {
    width: 46%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 53px 10px 0;
    z-index: 9;
}
.banner_info h2 {
    font-size: 48px;
    padding: 0 0 22px;
    line-height: 1.2;
}
.banner_info p {
    font-size: 22px;
}
.banner_info a {
    padding: 12px 32px;
    background: #439FF1;
    box-shadow: 0px 4px 49px rgb(0 7 72 / 12%);
    backdrop-filter: blur(25px);
    border-radius: 4px;
    display: inline-block;
    font-size: 18px;
    margin: 9px 0 0;
}
.videobox,.videobox .playbtn{
    display: none;
}
.play_btn_bottom {
    position: absolute;
    right: 52px;
    bottom: 31px;
    cursor: pointer;
}
.play_btn_bottom img {
    cursor: pointer;
    width: 41px;
}

/*hero section end*/



@media(max-width: 1250px){
    .container{
        width: 100%;
    }
    .banner_wrap {
        padding: 85px 22px 129px;
    }
    .banner_info h2 {
        font-size: 39px;
    }
}

@media(max-width: 1024px){
    .container{
        width: 100%;
    }
    .banner_info {
        flex: 0 0 72%;
        max-width: 72%;
    }
    .banner_info p {
        font-size: 21px;
    }
    
    .banner_wrap {
        padding: 53px 22px 57px;
    }
    .banner_info {
        padding: 0 10px 0;
    }

    
}

@media(max-width: 767px){
    .container{
        width: auto;
    }
    .videobox{position: relative;}
    .videobox,.videobox .playbtn{
        display: block;
    }
    .videobox  img{
        width: 100%;
        display: none;
    }
    .playbtn a img{width: auto;}
    .videobox .playbtn {
        position: absolute;
        right: 13px;
        bottom: 7px;
    }
    .banner_wrap {
        padding: 12px 0 0;
        background: #011329;
    }
    .flex_row {
        display: inline-block;
    }
    .banner_info h2 {
        line-height: 1.4;
        font-size: 23px;
    }
    .banner_info {
        flex: 100%;
        max-width: 100%;
        padding: 20px 11px;
    }
    .banner_info p {
        font-size: 14px;
        line-height: 1.6;
    }
    .banner_info{
        width: 100%;
    }
    .banner_wrap video {
        height: auto;
        position: static;
    }
    .banner_wrap {
        overflow: visible;
    }
    .play_btn_bottom{display: none;}

}

